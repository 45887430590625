import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[noSpaces]',
  standalone: true
})
export class NoSpacesDirective {

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const cleanedValue = input.value.replace(/\s+/g, '');
    this.ngControl.control?.setValue(cleanedValue);
  }
}
